// Third party libraries
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import * as immutable from 'object-path-immutable';
import { useHistory, Prompt } from 'react-router-dom';
import { isEqual } from 'lodash';
import { connectSocket, disconnectSocket } from '../../context/socket';
import { Icon } from '../Icon';
import { IconNames, FontFamiliesOptions, FontSizeOptions } from '../../util/const';
// Components
import { BtnSubmit } from '../btn-submit/Btn-submit';
import { ExtraOptions } from '../extra-options/Extra-options';
import { MiscCheckMarks } from '../misc-checkmarks/Misc-checkmarks';

import { FormContainer } from '../form-container/Form-container';
import { getUserId, getCompanyId } from '../../clientStore/authSlice/auth-slice';
import {
  getRoomsAreasSavingStatus,
  setRoomsAreasSavingStatus,
  setRoomsAreasLoadingStatus,
  getRoomsAreasLoadingStatus
} from '../../clientStore/roomsAreasSlice/roomsAreas-slice';
import {
  saveRoomsAreas,
  updateRoomsAreas,
} from '../../clientStore/roomsAreasSlice/roomsAreas-async-thunk';

// DataModel
import { roomsAreasDataModel } from '../../models/roomsAreas';
// Utils
import { fetchedData, removeByIndex } from '../../util/utils';
//Styles
import styles from './RoomsAreasForm.module.css';
import { isRoomsAreasFormValid } from '../../util/formValidations';
import { getCompany, getCompanyLoadingStatus } from '../../clientStore/companySlice/company-slice';
import { loadCompany } from '../../clientStore/companySlice/company-async-thunk';
import { DepartmentRadiobuttons } from '../department-radiobuttons/Department-radiobuttons';

export const RoomsAreasForm = ({ roomsAreasData, formType = 'new', roomsAreasId = undefined }) => {
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector(getUserId);
  const companyId = useSelector(getCompanyId);
  const savedStatus = useSelector(getRoomsAreasSavingStatus);



  var roomsAreas = roomsAreasData ? roomsAreasData : roomsAreasDataModel;


  const [formValue, setFormValue] = useState({
    ...roomsAreas,
    authorId: userId,
    companyId,
  });

  const unChangedFormValue = {
    ...roomsAreas,
    authorId: userId,
    companyId,
  }

  useEffect(() => {
    if (savedStatus === 'successed') {
      setIsLoading(false);
      setIsFormDirty(false)
      window.location.reload();
    }
  }, [savedStatus, dispatch]);



  const formSubmit = (evt) => {
    evt.preventDefault();
    setIsFormDirty(false)
    if (isRoomsAreasFormValid(formValue)) {
      setIsLoading(true);
      switch (formType) {
        case 'new':
          //if roomsAreas with the name exists in company inform user with message: RoomsAreas with name ${roomsAreasName} already exists
          dispatch(saveRoomsAreas(formValue));
          break;
        case 'edit':
          dispatch(updateRoomsAreas(formValue, roomsAreasId));
          break;
        default:
          throw new Error('Invalid form type');
      }

      setFormValue({
        ...roomsAreasDataModel,
        authorId: userId,
        companyId,
      });
    }
    else {
      alert("Please fill all required fields")
    }
  };

  const onFormValueChange = (path, method, value) => {
    setFormValue(immutable[method](formValue, path, value));
  };

  const setItemsValue = (evt, index) => {
    onFormValueChange(`rooms.${index}.${evt.target.name}`, 'set', evt.target.value);
  }

  const addItem = (index) => {
    const newItem = {
      [`roomName${index}`]: '',
    }
    onFormValueChange(`rooms`, 'push', newItem);
  }

  const removeItem = (index) => {
    if (window.confirm('This action will remove item. Are you sure?')) {
      let newItems = removeByIndex([...formValue.rooms], index)
      onFormValueChange(`rooms`, 'set', newItems);
    }
  }

  return (
    <FormContainer>
      <Prompt
        when={isFormDirty}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <form className={styles.form}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title1}>Rooms & Areas</h3>
        </div>

        <section style={{ marginTop: '5px', marginLeft: '5px' }}>
          {formValue.rooms.map((item, idx) => (
            <div key={idx} style={{ display: 'flex', alignItems: 'flex-start', padding: '5px' }}>
              <label
                className={styles.inputContainer}
                htmlFor={`roomName${idx}`}
              >
                <p className={styles.inputName}>Room name</p>
                <input
                  type="text"
                  name={`roomName${idx}`}
                  value={item[`roomName${idx}`]}
                  onChange={event => setItemsValue(event, idx)}
                  className={styles.input}
                />
              </label>
              <button
                onClick={
                  (evt) => {
                    evt.preventDefault();
                    removeItem(idx);
                  }
                }
                className={styles.removeExtraBtnList}><Icon name={IconNames.MINUS} />
              </button>
            </div>
          ))}
          <div style={{ display: 'flex', borderBottom: '1px solid  rgba(77, 182, 172, .5)' }}>
            <button
              className={styles.extraBtn}
              onClick={
                (evt) => {
                  evt.preventDefault();
                  addItem(formValue.rooms.length);
                }
              }
            >
              Add room
              <Icon name={IconNames.PLUS} />
            </button>
          </div>
          <div style={{ display: 'flex', padding: '5px', borderBottom: '1px solid rgba(77, 182, 172, .5)' }}>
            Format text:
            <label className={styles.inputContainer}>
              <input
                type="checkbox"
                id="bold"
                name="bold"
                checked={formValue.bold}
                onChange={event => onFormValueChange(`bold`, 'set', event.target.checked)}
              />
              <p className={styles.inputName}>Bold</p>
            </label>
            <label className={styles.inputContainer}>
              <input
                type="checkbox"
                id="italic"
                name="italic"
                checked={formValue.italic}
                onChange={event => onFormValueChange(`italic`, 'set', event.target.checked)}
              />
              <p className={styles.inputName}>Italic</p>
            </label>
            <label className={styles.inputContainer}>
              <input
                type="checkbox"
                id="underline"
                name="underline"
                checked={formValue.underline}
                onChange={event => onFormValueChange(`underline`, 'set', event.target.checked)}
              />
              <p className={styles.inputName}>Underline</p>
            </label>
            <label className={styles.label}>
              Font Family
              <select
                className={styles.select}
                value={formValue.fontFamily}
                onChange={event => onFormValueChange(`fontFamily`, 'set', event.target.value)}
              >
                {FontFamiliesOptions.map((fontFamily) => {
                  return (
                    <option key={nanoid(FontFamiliesOptions.length)} value={fontFamily}>{fontFamily}</option>
                  );
                })}
              </select>
            </label>
            <label className={styles.label}>
              Font Size
              <select
                className={styles.select}
                value={formValue.fontSize}
                onChange={event => onFormValueChange(`fontSize`, 'set', event.target.value)}
              >
                {FontSizeOptions.map((fontSize) => {
                  return (
                    <option key={nanoid(FontSizeOptions.length)} value={fontSize}>{fontSize}</option>
                  );
                })}
              </select>
            </label>
          </div>
          <div style={{ borderBottom: '1px solid rgba(77, 182, 172, .5)', padding:'5px' }}>
            <label className={styles.inputContainer}>
              <input
                type="checkbox"
                id="insertLineBreakAN"
                name="insertLineBreakAN"
                checked={formValue.insertLineBreakAN}
                onChange={event => onFormValueChange(`insertLineBreakAN`, 'set', event.target.checked)}
              />
              <p className={styles.inputName}>Insert line break</p>
            </label>
          </div>

          <BtnSubmit
            isActive={true}
            action={formSubmit}
            name={'Save'}
          />
        </section>
      </form>
    </FormContainer >
  );
};
