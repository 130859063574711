import React, { useState, useContext, useCallback, useEffect } from 'react';
import { SocketContext } from '../../context/socket';
import useCollapse from 'react-collapsed'

import { generateNotIncludedServicesText, generateUnpackingText, getAdditionalServicesResText, getChargesResText, getCheckMarkLabelById, getCurrentRatePercent, getHigherNumberOfHours, getTagsValues, getTotalTimeRange, replaceOptionsTagsByValues, replaceTagsByValues } from '../../util/utils';
// Styles
import styles from "./CalculationTwoDays150Params.module.css";
//Components
import { CalculationSeparateDayView } from '../calculation-separate-day-view/Calculation-separate-day-view';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import CopyIcon from '../results-copy-icon/Results-copy-icon';
import { ExtraOptionsResults } from '../extra-options-results/Extra-options-results';
import { AdditionalServicesResults } from '../additional-services-results/Additional-services-results';
import { AdditionalServicesEstimateInfo } from '../additional-services-estimate-info/Additional-services-estimate-info';
import TruckloadsView from '../truckloads/TruckloadsView';
import { setTotal_cubesG, setTotalFragileBoxes, setTotalMediumBoxes, setTotalSmallBoxes } from '../../clientStore/calculatedParamsSlice/calculated-params-slice';
import { useDispatch } from 'react-redux';
import PackingSuppliesOnTheGo from '../packingSuppliesOnTheGo/Packing-supplies-on-the-go';

export const CalculationTwoDays150Params = ({
  commonValues,
  department,
  twoDays150Miles,
  selectedMiscCheckMarks,
  checkboxValueChange,
  editorState,
  tags,
  setPreviewText, setOptionsPreviewText,
  popUpShown,
  calculationData,
  setShowGenerateEmail, furniture, roomsAreas, setTagsValuesDefault, customTagsValues, showCustomTags,
  scrollRefs,
  highLightItem,
}) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const [Total_cubes, setTotal_cubes] = useState(0);
  const [Total_small_boxes, setTotal_small_boxes] = useState(0);
  const [Total_medium_boxes, setTotal_medium_boxes] = useState(0);
  const [Total_fragile_boxes, setTotal_fragile_boxes] = useState(0);
  const [Total_box_cubes, setTotal_box_cubes] = useState(0);
  const [Truckloads, setTruckloads] = useState(0);
  const [Stop_CN_Truckload, setStop_CN_Truckload] = useState(0);
  const [Stop_1_Truckload, setStop_1_Truckload] = useState(0);
  const [BIGGEST_or_OK, setBIGGEST_or_OK] = useState(0);
  const [Heavy_fee, setHeavy_fee] = useState(0);
  const [Packing_kit_Show, setPacking_kit_Show] = useState(0);
  const [Packing_kit_total_amount_with_taxes, setPacking_kit_total_amount_with_taxes] = useState(0);
  const [TWO_Trips_Or_OK_Day2, setTWO_Trips_Or_OK_Day2] = useState(0);
  const [Loading_hours_150miles, setLoading_hours_150miles] = useState(0);
  const [Total_Hours_150miles_Day1, setTotal_Hours_150miles_Day1] = useState(0);
  const [Unloading_hours_150miles, setUnloading_hours_150miles] = useState(0);
  const [Total_Hours_150miles_Day2, setTotal_Hours_150miles_Day2] = useState(0);
  const [Movers_150miles_Day1, setMovers_150miles_Day1] = useState(0);
  const [Movers_150miles_Day2, setMovers_150miles_Day2] = useState(0);
  const [Total_Destinationfee_150miles, setTotal_Destinationfee_150miles] = useState(0);
  const [Double_drive_150miles_Day1, setDouble_drive_150miles_Day1] = useState(0);
  const [Lower_Number_of_Hours_150miles_Day1, setLower_Number_of_Hours_150miles_Day1] = useState(0);
  const [Higher_Number_of_Hours_150miles_Day2, setHigher_Number_of_Hours_150miles_Day2] = useState(0);
  const [Higher_Number_of_Hours_150miles_Day1, setHigher_Number_of_Hours_150miles_Day1] = useState(0);
  const [Trucks_150miles_Day2, setTrucks_150miles_Day2] = useState(0);
  const [Double_drive_150miles_Day2, setDouble_drive_150miles_Day2] = useState(0);
  const [Lower_Number_of_Hours_150miles_Day2, setLower_Number_of_Hours_150miles_Day2] = useState(0);
  const [Total_Card_150miles_2Days, setTotal_Card_150miles_2Days] = useState(0);
  const [Total_Cash_150miles_Day2, setTotal_Cash_150miles_Day2] = useState(0);
  const [Total_Cash_150miles_2Days, setTotal_Cash_150miles_2Days] = useState(0);
  const [Driving_time_with_DDT_150miles_Day1, setDriving_time_with_DDT_150miles_Day1] = useState(0);
  const [Driving_time_with_DDT_150miles_Day2, setDriving_time_with_DDT_150miles_Day2] = useState(0);
  const [Rate_cash_150miles_Day1, setRate_cash_150miles_Day1] = useState(0);
  const [Total_Cash_150miles_Day1, setTotal_Cash_150miles_Day1] = useState(0);
  const [Total_Card_150miles_Day1, setTotal_Card_150miles_Day1] = useState(0);
  const [Total_Card_150miles_Day2, setTotal_Card_150miles_Day2] = useState(0);
  const [Rate_card_150miles_Day1, setRate_card_150miles_Day1] = useState(0);
  const [Trucks_150miles_Day1, setTrucks_150miles_Day1] = useState(0);
  const [Destination_fee_150miles, setDestination_fee_150miles] = useState(0);
  const [Truck_fee_150miles_Day1, setTruck_fee_150miles_Day1] = useState(0);
  const [Rate_cash_150miles_Day2, setRate_cash_150miles_Day2] = useState(0);
  const [Rate_card_150miles_Day2, setRate_card_150miles_Day2] = useState(0);
  const [Truck_fee_150miles_Day2, setTruck_fee_150miles_Day2] = useState(0);
  const [Trucks_Unpacking_Separate_Day, setTrucks_Unpacking_Separate_Day] = useState(0)
  const [Movers_Unpacking_Separate_Day, setMovers_Unpacking_Separate_Day] = useState(0)
  const [Unpacking_Separate_Day_Lower_hours, setUnpacking_Separate_Day_Lower_hours] = useState(0)
  const [Unpacking_Separate_Day_Higher_hours, setUnpacking_Separate_Day_Higher_hours] = useState(0)
  const [Total_Card_Unpacking_Separate_Day, setTotal_Card_Unpacking_Separate_Day] = useState(0)
  const [Total_Cash_Unpacking_Separate_Day, setTotal_Cash_Unpacking_Separate_Day] = useState(0)
  const [Rate_cash_Unpacking_Separate_Day, setRate_cash_Unpacking_Separate_Day] = useState(0)
  const [Rate_card_Unpacking_Separate_Day, setRate_card_Unpacking_Separate_Day] = useState(0)
  const [Truck_fee_Unpacking_Separate_Day, setTruck_fee_Unpacking_Separate_Day] = useState(0)
  const [Combined_mileage, setCombined_mileage] = useState(0)
  const [Packing_Charge, setPacking_Charge] = useState(0)
  const [Time_to_pack, setTime_to_pack] = useState(0);
  const [UnPacking_Charge, setUnPacking_Charge] = useState(0)
  const [Flat_rate_Unpacking_Separate_Day, setFlat_rate_Unpacking_Separate_Day] = useState(0)
  const [Flat_rate_Unpacking_Separate_Day_cash, setFlat_rate_Unpacking_Separate_Day_cash] = useState(0)
  const [Flat_rate_Cash_150_miles, setFlat_rate_Cash_150_miles] = useState(0)
  const [Flat_rate_Card_150_miles, setFlat_rate_Card_150_miles] = useState(0)
  const [Total_cubes_Within_Building, setTotal_cubes_Within_Building] = useState(0)
  const [Truckloads_to_move, setTruckloads_to_move] = useState(0);

  const isCuFtDepartment = department?.type === 'cuft';


  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  useEffect(() => {
    socket.emit('getFormulasValues', { calculationType: 'twoDays150Miles', commonValues: commonValues, twoDays150Miles: twoDays150Miles, department: department })

    socket.on('Total_cubes', (data) => { setTotal_cubes(data); dispatch(setTotal_cubesG(data)); });
    socket.on('Total_small_boxes', (data) => {setTotal_small_boxes(data); dispatch(setTotalSmallBoxes(data));});
    socket.on('Total_medium_boxes', (data) => {setTotal_medium_boxes(data); dispatch(setTotalMediumBoxes(data));});
    socket.on('Total_fragile_boxes', (data) => { setTotal_fragile_boxes(data); dispatch(setTotalFragileBoxes(data)); });
    socket.on('Total_box_cubes', (data) => setTotal_box_cubes(data));
    socket.on('Truckloads', (data) => setTruckloads(data));
    socket.on('Stop_CN_Truckload', (data) => setStop_CN_Truckload(data));
    socket.on('Stop_1_Truckload', (data) => setStop_1_Truckload(data));
    socket.on('BIGGEST_or_OK', (data) => setBIGGEST_or_OK(data));
    socket.on('Heavy_fee', (data) => setHeavy_fee(data));
    socket.on('Packing_kit_Show', (data) => setPacking_kit_Show(data));
    socket.on('Packing_kit_total_amount_with_taxes', (data) => setPacking_kit_total_amount_with_taxes(data));
    socket.on('TWO_Trips_Or_OK_Day2', (data) => setTWO_Trips_Or_OK_Day2(data));
    socket.on('Loading_hours_150miles', (data) => setLoading_hours_150miles(data));
    socket.on('Total_Hours_150miles_Day1', (data) => setTotal_Hours_150miles_Day1(data));
    socket.on('Unloading_hours_150miles', (data) => setUnloading_hours_150miles(data));
    socket.on('Total_Hours_150miles_Day2', (data) => setTotal_Hours_150miles_Day2(data));
    socket.on('Movers_150miles_Day1', (data) => setMovers_150miles_Day1(data));
    socket.on('Movers_150miles_Day2', (data) => setMovers_150miles_Day2(data));
    socket.on('Total_Destinationfee_150miles', (data) => setTotal_Destinationfee_150miles(data));
    socket.on('Double_drive_150miles_Day1', (data) => setDouble_drive_150miles_Day1(data));
    socket.on('Lower_Number_of_Hours_150miles_Day1', (data) => setLower_Number_of_Hours_150miles_Day1(data));
    socket.on('Higher_Number_of_Hours_150miles_Day2', (data) => setHigher_Number_of_Hours_150miles_Day2(data));
    socket.on('Higher_Number_of_Hours_150miles_Day1', (data) => setHigher_Number_of_Hours_150miles_Day1(data));
    socket.on('Trucks_150miles_Day2', (data) => setTrucks_150miles_Day2(data));
    socket.on('Double_drive_150miles_Day2', (data) => setDouble_drive_150miles_Day2(data));
    socket.on('Lower_Number_of_Hours_150miles_Day2', (data) => setLower_Number_of_Hours_150miles_Day2(data));
    socket.on('Total_Card_150miles_2Days', (data) => setTotal_Card_150miles_2Days(data));
    socket.on('Total_Cash_150miles_Day2', (data) => setTotal_Cash_150miles_Day2(data));
    socket.on('Total_Cash_150miles_2Days', (data) => setTotal_Cash_150miles_2Days(data));
    socket.on('Driving_time_with_DDT_150miles_Day1', (data) => setDriving_time_with_DDT_150miles_Day1(data));
    socket.on('Driving_time_with_DDT_150miles_Day2', (data) => setDriving_time_with_DDT_150miles_Day2(data));
    socket.on('Rate_cash_150miles_Day1', (data) => setRate_cash_150miles_Day1(data));
    socket.on('Total_Cash_150miles_Day1', (data) => setTotal_Cash_150miles_Day1(data));
    socket.on('Total_Card_150miles_Day1', (data) => setTotal_Card_150miles_Day1(data));
    socket.on('Total_Card_150miles_Day2', (data) => setTotal_Card_150miles_Day2(data));
    socket.on('Rate_card_150miles_Day1', (data) => setRate_card_150miles_Day1(data));
    socket.on('Trucks_150miles_Day1', (data) => setTrucks_150miles_Day1(data));
    socket.on('Destination_fee_150miles', (data) => setDestination_fee_150miles(data));
    socket.on('Truck_fee_150miles_Day1', (data) => setTruck_fee_150miles_Day1(data));
    socket.on('Rate_cash_150miles_Day2', (data) => setRate_cash_150miles_Day2(data));
    socket.on('Rate_card_150miles_Day2', (data) => setRate_card_150miles_Day2(data));
    socket.on('Truck_fee_150miles_Day2', (data) => setTruck_fee_150miles_Day2(data));
    socket.on('Trucks_Unpacking_Separate_Day', (data) => setTrucks_Unpacking_Separate_Day(data));
    socket.on('Movers_Unpacking_Separate_Day', (data) => setMovers_Unpacking_Separate_Day(data));
    socket.on('Unpacking_Separate_Day_Lower_hours', (data) => setUnpacking_Separate_Day_Lower_hours(data));
    socket.on('Unpacking_Separate_Day_Higher_hours', (data) => setUnpacking_Separate_Day_Higher_hours(data));
    socket.on('Total_Card_Unpacking_Separate_Day', (data) => setTotal_Card_Unpacking_Separate_Day(data));
    socket.on('Total_Cash_Unpacking_Separate_Day', (data) => setTotal_Cash_Unpacking_Separate_Day(data));
    socket.on('Rate_cash_Unpacking_Separate_Day', (data) => setRate_cash_Unpacking_Separate_Day(data));
    socket.on('Rate_card_Unpacking_Separate_Day', (data) => setRate_card_Unpacking_Separate_Day(data));
    socket.on('Truck_fee_Unpacking_Separate_Day', (data) => setTruck_fee_Unpacking_Separate_Day(data));
    socket.on('Combined_mileage', (data) => { setCombined_mileage(data) });
    socket.on('Packing_Charge', (data) => { setPacking_Charge(data) });
    socket.on('Time_to_pack', (data) => setTime_to_pack(data));
    socket.on('UnPacking_Charge', (data) => setUnPacking_Charge(data));
    socket.on('Flat_rate_Unpacking_Separate_Day', (data) => setFlat_rate_Unpacking_Separate_Day(data));
    socket.on('Flat_rate_Unpacking_Separate_Day_cash', (data) => setFlat_rate_Unpacking_Separate_Day_cash(data));
    socket.on('Flat_rate_Cash_150_miles', (data) => setFlat_rate_Cash_150_miles(data));
    socket.on('Flat_rate_Card_150_miles', (data) => setFlat_rate_Card_150_miles(data));
    socket.on('Total_cubes_Within_Building', (data) => setTotal_cubes_Within_Building(data));
    socket.on('Truckloads_to_move', (data) => { setTruckloads_to_move(data) });
  }, [commonValues, twoDays150Miles, department])

  useEffect(() => {
    if (editorState) {
      let templateText = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      let tagValues = getTagsValues({
        calculation: calculationData, tags,
        calculatedParams: {
          Total_cubes: Total_cubes,
          Total_small_boxes: Total_small_boxes,
          Total_medium_boxes: Total_medium_boxes,
          Total_fragile_boxes: Total_fragile_boxes,
          Total_box_cubes: Total_box_cubes,
          Truckloads: Truckloads,
          Truckloads_to_move: Truckloads_to_move,
          Total_cubes_Within_Building: Total_cubes_Within_Building,
          Stop_CN_Truckload: Stop_CN_Truckload,
          Stop_1_Truckload: Stop_1_Truckload,
          BIGGEST_or_OK: BIGGEST_or_OK,
          Heavy_fee: Heavy_fee,
          Packing_kit_Show: Packing_kit_Show,
          Packing_kit_total_amount_with_taxes: Packing_kit_total_amount_with_taxes,
          TWO_Trips_Or_OK_Day2: TWO_Trips_Or_OK_Day2,
          Loading_hours_150miles: Loading_hours_150miles,
          Total_Hours_150miles_Day1: Total_Hours_150miles_Day1,
          Unloading_hours_150miles: Unloading_hours_150miles,
          Total_Hours_150miles_Day2: Total_Hours_150miles_Day2,
          Movers_150miles_Day1: Movers_150miles_Day1,
          Movers_150miles_Day2: Movers_150miles_Day2,
          Total_Destinationfee_150miles: Total_Destinationfee_150miles,
          Double_drive_150miles_Day1: Double_drive_150miles_Day1,
          Lower_Number_of_Hours_150miles_Day1: Lower_Number_of_Hours_150miles_Day1,
          Higher_Number_of_Hours_150miles_Day2: Higher_Number_of_Hours_150miles_Day2,
          Higher_Number_of_Hours_150miles_Day1: Higher_Number_of_Hours_150miles_Day1,
          Trucks_150miles_Day2: Trucks_150miles_Day2,
          Double_drive_150miles_Day2: Double_drive_150miles_Day2,
          Lower_Number_of_Hours_150miles_Day2: Lower_Number_of_Hours_150miles_Day2,
          Total_Card_150miles_2Days: Total_Card_150miles_2Days,
          Total_Cash_150miles_Day2: Total_Cash_150miles_Day2,
          Total_Cash_150miles_2Days: Total_Cash_150miles_2Days,
          Driving_time_with_DDT_150miles_Day1: Driving_time_with_DDT_150miles_Day1,
          Driving_time_with_DDT_150miles_Day2: Driving_time_with_DDT_150miles_Day2,
          Rate_cash_150miles_Day1: Rate_cash_150miles_Day1,
          Total_Cash_150miles_Day1: Total_Cash_150miles_Day1,
          Total_Card_150miles_Day1: Total_Card_150miles_Day1,
          Total_Card_150miles_Day2: Total_Card_150miles_Day2,
          Rate_card_150miles_Day1: Rate_card_150miles_Day1,
          Trucks_150miles_Day1: Trucks_150miles_Day1,
          Destination_fee_150miles: Destination_fee_150miles,
          Truck_fee_150miles_Day1: Truck_fee_150miles_Day1,
          Rate_cash_150miles_Day2: Rate_cash_150miles_Day2,
          Rate_card_150miles_Day2: Rate_card_150miles_Day2,
          Truck_fee_150miles_Day2: Truck_fee_150miles_Day2,
          Trucks_Unpacking_Separate_Day: Trucks_Unpacking_Separate_Day,
          Movers_Unpacking_Separate_Day: Movers_Unpacking_Separate_Day,
          Unpacking_Separate_Day_Lower_hours: Unpacking_Separate_Day_Lower_hours,
          Unpacking_Separate_Day_Higher_hours: Unpacking_Separate_Day_Higher_hours,
          Total_Card_Unpacking_Separate_Day: Total_Card_Unpacking_Separate_Day,
          Total_Cash_Unpacking_Separate_Day: Total_Cash_Unpacking_Separate_Day,
          Rate_cash_Unpacking_Separate_Day: Rate_cash_Unpacking_Separate_Day,
          Rate_card_Unpacking_Separate_Day: Rate_card_Unpacking_Separate_Day,
          Truck_fee_Unpacking_Separate_Day: Truck_fee_Unpacking_Separate_Day,
          Flat_rate_Unpacking_Separate_Day: Flat_rate_Unpacking_Separate_Day,
          Flat_rate_Unpacking_Separate_Day_cash: Flat_rate_Unpacking_Separate_Day_cash,
          Combined_mileage: Combined_mileage,
          Flat_rate_Cash_150_miles: Flat_rate_Cash_150_miles,
          Flat_rate_Card_150_miles: Flat_rate_Card_150_miles,
          Packing_Charge: Packing_Charge,
          UnPacking_Charge: UnPacking_Charge,
          Total_cubes_Within_Building: Total_cubes_Within_Building
        }, furniture, roomsAreas
      })
      const tagValuesCopy = JSON.parse(JSON.stringify(tagValues));
      if (typeof setTagsValuesDefault === 'function') {
        setTagsValuesDefault(prevState => ({
          ...prevState,
          [calculationData.calculationType]: tagValuesCopy
        }));
      }
      if (customTagsValues && Object.keys(customTagsValues).length > 0) {
        for (const key in customTagsValues) {
          tagValues[`|{${key}}`] = customTagsValues[key];
        }
      }
      setPreviewText(replaceTagsByValues(templateText, tagValues))
      setOptionsPreviewText(replaceOptionsTagsByValues(templateText, tagValues))
    }
  }, [popUpShown, tags, /* calculationData, */ showCustomTags]);

  const handleResCopy = (evt) => {
    evt.preventDefault();

    let unpackingText = generateUnpackingText(commonValues, department, isCuFtDepartment, Movers_Unpacking_Separate_Day, Rate_cash_Unpacking_Separate_Day, Rate_card_Unpacking_Separate_Day, Truck_fee_Unpacking_Separate_Day, Trucks_Unpacking_Separate_Day, Unpacking_Separate_Day_Lower_hours, Unpacking_Separate_Day_Higher_hours, UnPacking_Charge, Total_Cash_Unpacking_Separate_Day, Total_Card_Unpacking_Separate_Day, twoDays150Miles, Flat_rate_Unpacking_Separate_Day_cash, Flat_rate_Unpacking_Separate_Day)


    let chargesText = getChargesResText(department, twoDays150Miles.selectedChargesNamesDay1, Trucks_150miles_Day1);

    let chargesTextDay2 = getChargesResText(department, twoDays150Miles.selectedChargesNamesDay2, Trucks_150miles_Day2);


    let proceduresText = '';
    let procedureAdded = false;
    commonValues.procedures.forEach((procedure, pkIndex) => {
      if (procedure.procedureType !== '' && procedure.procedureType !== 'None' && twoDays150Miles.proceduresOnDay1 && !twoDays150Miles.proceduresOnDay2) {
        if (!procedureAdded) {
          proceduresText += 'Procedures:\n';
          procedureAdded = true;
        }
        proceduresText += '• ' + procedure.procedureType + ' x ' + procedure.numberOfProcedures + ':';
        if (Object.keys(department).length !== 0) {
          department.packing.cratings.forEach((crating, index) => {
            if (procedure.procedureType === crating[`crateName${index}`]) {
              proceduresText += ' $' + (Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]));
            }
          });
        }
        proceduresText += '\n';
      }
    });

    let proceduresTextDay2 = '';

    commonValues.procedures.forEach((procedure, pkIndex) => {
      if (procedure.procedureType !== '' && procedure.procedureType !== 'None' && twoDays150Miles.proceduresOnDay2) {
        proceduresTextDay2 += '• ' + procedure.procedureType + ' x ' + procedure.numberOfProcedures + ':';
        if (Object.keys(department).length !== 0) {
          department.packing.cratings.forEach((crating, index) => {
            if (procedure.procedureType === crating[`crateName${index}`]) {
              proceduresTextDay2 += ' $' + (Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]));
            }
          });
        }
        proceduresTextDay2 += '\n';
      }
    });

    var truckloads = (Number(Total_cubes_Within_Building) > 0) ? Truckloads_to_move : Truckloads;
    let additionalServicesText = getAdditionalServicesResText(department, commonValues.selectedAdditionalServices, Trucks_150miles_Day1, commonValues.additionalServicesPlaceType, Total_cubes);

    let notIncludedServicesText = generateNotIncludedServicesText(department, Total_cubes, commonValues)
    let suppliesPrices = `  • Small box: ${(Object.keys(department).length !== 0) ? department.packing.supplies.smallBox : ''}
  • Medium box: ${(Object.keys(department).length !== 0) ? department.packing.supplies.mediumBox : ''}
  • Large box: ${(Object.keys(department).length !== 0) ? department.packing.supplies.largeBox : ''}
  • Roll of packing paper: ${(Object.keys(department).length !== 0) ? department.packing.supplies.packingPapper : ''}
  • Roll of bubble wrap: ${(Object.keys(department).length !== 0) ? department.packing.supplies.bubbleWrap : ''}
         `;
    const invoiceText = `Total Cubic ft: ${Total_cubes.toFixed(2)} cu ft.
${(Total_cubes_Within_Building > 0) ? `Cubic ft within building: ${parseFloat(Total_cubes_Within_Building).toFixed(2)} cu ft.
Cubic ft to move: ${parseFloat(Total_cubes - Total_cubes_Within_Building).toFixed(2)} cu ft.` : ''}
Total Truckloads: ${truckloads}
${(truckloads > 1 && (Math.floor(Number(truckloads))) - Number(truckloads) < 0) ? Math.floor(truckloads) + ' truck(s) + ' + Math.floor(Number((Number(truckloads) - Math.floor(truckloads)).toFixed(2) * 100)) + "%" : ''}
${!isCuFtDepartment ? `Total miles:${parseFloat(Combined_mileage).toFixed(1)}` : `Total 2 days miles: ${Number(twoDays150Miles.milesDay1) + Number(twoDays150Miles.milesDay2)}
${(commonValues.unpacking === 'Separate Day') ? `Unpacking day Round trip miles: ${parseFloat(commonValues.milesToParkingCuFt * 2).toFixed(1)}` : ''}`}
${(commonValues.extraStops.filter((extraStop, index) => extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0).length > 0) ? `There will be ${commonValues.extraStops.filter((extraStop, index) => extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0).length} additional stops` : ''}
Day 1 (Loading→Parking lot)
Movers: ${Movers_150miles_Day1}
${(!isCuFtDepartment) ? `Rate: ${(Total_Destinationfee_150miles > 0)
        ?
        (department.truckFee.tfdfCombined)
          ? (department.rates && department.rates.calcMethod === 'deduct')
            ? "$" + Rate_cash_150miles_Day1 + "/h /"
            + "$" + Rate_card_150miles_Day1 + "/h "
            :
            "$" + Rate_card_150miles_Day1 + "/h "
          : (department.rates && department.rates.calcMethod === 'deduct') ? "$" + Rate_cash_150miles_Day1 + "/h /"
            + "$" + Rate_card_150miles_Day1 + "/h " +
            ((Truck_fee_150miles_Day1 !== 0) ? "+ $" + Truck_fee_150miles_Day1.toString() + " truck(s) fee" : "").toString()
            :
            "$" + Rate_card_150miles_Day1 + '/h ' +
            ((Truck_fee_150miles_Day1 !== 0) ? "+ $" + Truck_fee_150miles_Day1.toString() + " truck(s) fee" : "").toString()
        : (department.rates && department.rates.calcMethod === 'deduct')
          ? "$" + Rate_cash_150miles_Day1 + "/h /" + Rate_card_150miles_Day1 + "/h" +

          ((Truck_fee_150miles_Day1 !== 0) ? "+ $" + Truck_fee_150miles_Day1.toString() + " truck(s) fee" : "").toString()
          :
          "$" + Rate_card_150miles_Day1 + "/h " +
          ((Truck_fee_150miles_Day1 !== 0) ? "+ $" + Truck_fee_150miles_Day1.toString() + " truck(s) fee" : "").toString()
        }` : `${(Truck_fee_150miles_Day1) ? `Truck fee: $${Truck_fee_150miles_Day1}` : ''}`
      }
Trucks: ${Trucks_150miles_Day1}${twoDays150Miles.shuttleOnDay1 ? (("+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${twoDays150Miles.quantityDay1})` : ''))) : ""}
${((Object.keys(department).length !== 0) && department.extraOptions.charges.length > 0 && twoDays150Miles.selectedChargesNamesDay1.length > 0) ? `
Extra options:` : ''}
${chargesText}
${additionalServicesText}
${(!isCuFtDepartment) ? `Double Drive:${Double_drive_150miles_Day1}` : ''}
Total Time Range: ${getTotalTimeRange((department) ? department.minimumHours : 0, Lower_Number_of_Hours_150miles_Day1, Higher_Number_of_Hours_150miles_Day1)}
${proceduresText}
${(commonValues.packing !== "No" && !isCuFtDepartment) ?
        (commonValues.packingKitOverride > 0) ?
          `Packing Kit:${'$' + Packing_kit_Show} ${commonValues.packing} [$${Packing_kit_total_amount_with_taxes} with tax]`
          : `Packing Kit:${'$' + Packing_kit_Show} ${(commonValues.packing === "Yes") ? "Full" : commonValues.packing} [$${Packing_kit_total_amount_with_taxes} with tax]`
        : ""}
${(commonValues.unpacking === 'Same Day') ? `UNPACKING ${(commonValues.partialUnPacking) ? `PARTIAL` : ''}` : ''}
${(isCuFtDepartment && Packing_Charge) ? `Packing Charge:${Packing_Charge}` : ''}
${(commonValues.packing !== "No" && (isCuFtDepartment ? department.packing.packingKits.enabled : true)) ?
  `Packing Supplies On The Go:`+`${department.packing.supplies.calculationMethod === 'suppliesPrices' ? suppliesPrices : ''}
${(!isCuFtDepartment) ? suppliesPrices : ''}
${department.packing.supplies.calculationMethod === 'perBox' ?
    `Per 1 packed box price: $${department.packing.supplies.perOnePackedBox}`
    : ''}`
  : ''
}
${(isCuFtDepartment) ? notIncludedServicesText : ''}
Day2 (Parking lot→Unloading)
Movers: ${Movers_150miles_Day2}
${(!isCuFtDepartment) ? `Rate: ${(Total_Destinationfee_150miles > 0)
        ?
        (department.truckFee.tfdfCombined)
          ? (department.rates && department.rates.calcMethod === 'deduct')
            ? "$" + Rate_cash_150miles_Day2 + "/h /"
            + "$" + Rate_card_150miles_Day2 + "/h "
            :
            "$" + Rate_card_150miles_Day2 + "/h "
          : (department.rates && department.rates.calcMethod === 'deduct') ? "$" + Rate_cash_150miles_Day2 + "/h /"
            + "$" + Rate_card_150miles_Day2 + "/h " +
            ((Truck_fee_150miles_Day2 !== 0) ? "+ $" + Truck_fee_150miles_Day2.toString() + " truck(s) fee" : "").toString()
            :
            "$" + Rate_card_150miles_Day2 + '/h ' +
            ((Truck_fee_150miles_Day2 !== 0) ? "+ $" + Truck_fee_150miles_Day2.toString() + " truck(s) fee" : "").toString()
        : (department.rates && department.rates.calcMethod === 'deduct')
          ? "$" + Rate_cash_150miles_Day2 + "/h /" + Rate_card_150miles_Day2 + "/h" +

          ((Truck_fee_150miles_Day2 !== 0) ? "+ $" + Truck_fee_150miles_Day2.toString() + " truck(s) fee" : "").toString()
          :
          "$" + Rate_card_150miles_Day2 + "/h " +
          ((Truck_fee_150miles_Day2 !== 0) ? "+ $" + Truck_fee_150miles_Day2.toString() + " truck(s) fee" : "").toString()
        }` : `${(Truck_fee_150miles_Day2) ? `Truck fee: $${Truck_fee_150miles_Day2}` : ''}`
      }
Trucks: ${Trucks_150miles_Day2}${twoDays150Miles.shuttleOnDay2 ? (("+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${twoDays150Miles.quantityDay2})` : ''))) : ""}
${(BIGGEST_or_OK !== 'OK') ? BIGGEST_or_OK : ""}
${(TWO_Trips_Or_OK_Day2 !== 'OK') ? TWO_Trips_Or_OK_Day2 : ""}
${((Object.keys(department).length !== 0) && department.extraOptions.charges.length > 0 && twoDays150Miles.selectedChargesNamesDay2.length > 0) ? `
Extra options:` : ''}
${chargesTextDay2}
${(!isCuFtDepartment) ? `Double Drive:${Double_drive_150miles_Day2}` : ''}
${Destination_fee_150miles > 0 ? `Destination fee: $${Destination_fee_150miles}` : ''}
Total Time Range: ${getTotalTimeRange((department) ? department.minimumHours : 0, Lower_Number_of_Hours_150miles_Day2, Higher_Number_of_Hours_150miles_Day2)}
${proceduresTextDay2}
${(Heavy_fee > 0) ? `Extra Heavy Items Charge: $${Heavy_fee}` : ''}
${commonValues.liftGate ? ' | LIFTGATE' : ''}
${commonValues.hardFloorCovers ? ' | HARDWOOD FLOOR COVERS' : ''}
${commonValues.filmFloorCovers ? ' | CARPET FLOOR COVERS' : ''}${selectedMiscCheckMarks.map(checkMark => ` | ${getCheckMarkLabelById(checkMark, department.miscCheckMarks.miscCheckMarks).toUpperCase()}`).join('')}
${(twoDays150Miles.additionalAmount) ? `Additional amount: $${twoDays150Miles.additionalAmount}` : ''}
${(!isCuFtDepartment) ? `NOT TO EXCEED PRICE: $${(department.rates && department.rates.calcMethod === 'deduct') ?
        Total_Cash_150miles_2Days + ' cash / $' + Total_Card_150miles_2Days + " card"
        : Total_Card_150miles_2Days}
${(department.rates && department.rates.calcMethod !== 'deduct') ? `NOT TO EXCEED PRICE with ${getCurrentRatePercent(twoDays150Miles, department)}% cash discount: $${Total_Cash_150miles_2Days}` : ""}` : ``}
${(isCuFtDepartment) ? `FLAT RATE: $${(department.rates && department.rates.calcMethod === 'deduct') ?
        Flat_rate_Cash_150_miles + ' cash / $' + Flat_rate_Card_150_miles + " card"
        : Flat_rate_Card_150_miles}
${(department.rates && department.rates.calcMethod !== 'deduct') ? `FLAT RATE with ${getCurrentRatePercent(twoDays150Miles, department)}% cash discount: $${Flat_rate_Cash_150_miles}` : ""}` : ``} 
${unpackingText}
`;




    // Create a textarea element to hold the text
    const textArea = document.createElement("textarea");
    textArea.value = invoiceText.replace(/\n{2,}/g, '\n\n') + '\n';;

    // Append the textarea to the document
    document.body.appendChild(textArea);

    // Select and copy the text
    textArea.select();
    document.execCommand("copy");

    // Remove the textarea from the document
    document.body.removeChild(textArea);

  }

  if (typeof setShowGenerateEmail === 'function') {
    if (department && Object.keys(department).length > 0 && twoDays150Miles.firstDayRate && twoDays150Miles.secondDayRate
      && Trucks_150miles_Day1 > 0 && Trucks_150miles_Day2 > 0 && Movers_150miles_Day1 > 0 && Movers_150miles_Day2 > 0) {
      setShowGenerateEmail(true);
    } else {
      setShowGenerateEmail(false);
    }
  }

  return (
    <ul className={styles.paramsSection}>
      <button {...getToggleProps()} className={styles.btnSubmit}>
        <i className={isExpanded ? styles.up : styles.down}></i>
      </button>
      <section {...getCollapseProps()}>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Small boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>{Total_small_boxes}</p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Medium boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>{Total_medium_boxes}</p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Fragile boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_fragile_boxes}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Boxes cubic ft:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_box_cubes.toFixed(2) + " cu ft."}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Time to pack for 1 mover:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Time_to_pack).toFixed(2) + " h"}
          </p>
        </li>
        <li className={styles.paramsItem} >
          <p className={styles.paramsName}>Truckloads from Stop 1:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Stop_1_Truckload).toFixed(3)}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>
            Truckloads from Additional stops:
          </p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Stop_CN_Truckload).toFixed(3)}
          </p>
        </li>
        <h4>DAY - 1 (Loading→Parking)</h4>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Loading hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Loading_hours_150miles).toFixed(3)} {' h'}
          </p>
        </li>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Drive Time:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Driving_time_with_DDT_150miles_Day1} {' h'}
          </p>
        </li>


        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Total Hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_Hours_150miles_Day1} {' hours'}
          </p>
        </li>
        {!isCuFtDepartment &&
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Day 1:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {(department.rates && department.rates.calcMethod === 'deduct') ?
                '$' + Total_Cash_150miles_Day1 + ' cash / $' + Total_Card_150miles_Day1 + " card"
                :
                <>
                  {'$' + Total_Card_150miles_Day1}
                </>}
            </p>
          </li>
        }

        <h4>DAY - 2 (Parking→Unloading)</h4>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Drive Time:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Driving_time_with_DDT_150miles_Day2} {' h'}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Unloading Hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Unloading_hours_150miles).toFixed(3)}{' h'}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Total Hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_Hours_150miles_Day2} {' hours'}
          </p>
        </li>

        {!isCuFtDepartment &&
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Day 2:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {(department.rates && department.rates.calcMethod === 'deduct') ?
                '$' + Total_Cash_150miles_Day2 + ' cash / $' + Total_Card_150miles_Day2 + " card"
                :
                <>
                  {'$' + Total_Card_150miles_Day2}
                </>}
            </p>
          </li>
        }

      </section>
      {(department && Object.keys(department).length > 0 && twoDays150Miles.firstDayRate && twoDays150Miles.secondDayRate
        && Trucks_150miles_Day1 > 0 && Trucks_150miles_Day2 > 0 && Movers_150miles_Day1 > 0 && Movers_150miles_Day2 > 0) ?
        <div>
          <h2 className={styles.titleCenter}><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Results  <CopyIcon onClick={handleResCopy} style={{ marginLeft: '5px' }} /></div> {(Total_box_cubes > 0 && commonValues.packing === 'No') ?
            <>
              <span style={{ color: 'red' }}> - No Packing
              </span>
              <label className={styles.commonLabeling} style={{ marginLeft: '5px' }}>
                Confirm
                <input
                  className={styles.mycheckbox}
                  type='checkbox'
                  name={'isNoPackingConfirmed'}
                  checked={commonValues.isNoPackingConfirmed}
                  value={commonValues.isNoPackingConfirmed}
                  onChange={checkboxValueChange}
                />
              </label>
            </> : ''}</h2>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Cubic ft:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                {Total_cubes.toFixed(2)} cu ft.
              </span>
            </p>
          </li>
          {(Total_cubes_Within_Building > 0) ? <>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Cubic ft within building:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                  {parseFloat(Total_cubes_Within_Building).toFixed(2)} cu ft.
                </span>
              </p>
            </li>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Cubic ft to move:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                  {parseFloat(Total_cubes - Total_cubes_Within_Building).toFixed(2)} cu ft.
                </span>
              </p>
            </li>
          </> : ''}
          <TruckloadsView Truckloads={Truckloads} Truckloads_to_move={Truckloads_to_move} Total_cubes_Within_Building={Total_cubes_Within_Building} />

          {!isCuFtDepartment ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Total miles:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                  {parseFloat(Combined_mileage).toFixed(1)}
                </span>
              </p>
            </li>
            : <>
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Total 2 days miles:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{Number(twoDays150Miles.milesDay1) + Number(twoDays150Miles.milesDay2)}</span></p>
              </li>
              {commonValues.unpacking === 'Separate Day' &&
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Unpacking day Round trip miles:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                      {parseFloat(commonValues.milesToParkingCuFt * 2).toFixed(1)}
                    </span>
                  </p>
                </li>
              }
            </>
          }
          {
            (commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length > 0) ?
              <li className={styles.paramsItem}><div>
                There will be <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{commonValues.extraStops.filter((extraStop, index) =>
                  extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
                ).length}</span> additional stops</div>
              </li>
              : ''
          }
          <h2 className={styles.titleCenter}>Day 1 (Loading→Parking lot)</h2>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Movers:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                {Movers_150miles_Day1}
              </span>
            </p>
          </li>

          {(!isCuFtDepartment) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Rate:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {(Total_Destinationfee_150miles > 0)
                  ?
                  (department.truckFee.tfdfCombined)
                    ? (department.rates && department.rates.calcMethod === 'deduct')
                      ? <> <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}></span>${Rate_cash_150miles_Day1}/h /<span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>${Rate_card_150miles_Day1}/h</span>
                      </> :
                      <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>${Rate_card_150miles_Day1}/h</span>
                    : (department.rates && department.rates.calcMethod === 'deduct') ? "$" + Rate_cash_150miles_Day1 + "/h /"
                      + "$" + Rate_card_150miles_Day1 + "/h " +
                      ((Truck_fee_150miles_Day1 !== 0) ? "+ $" + Truck_fee_150miles_Day1.toString() + " truck(s) fee" : "").toString()
                      :
                      "$" + Rate_card_150miles_Day1 + '/h ' +
                      ((Truck_fee_150miles_Day1 !== 0) ? "+ $" + Truck_fee_150miles_Day1.toString() + " truck(s) fee" : "").toString()
                  : (department.rates && department.rates.calcMethod === 'deduct')
                    ? "$" + Rate_cash_150miles_Day1 + "/h /" + Rate_card_150miles_Day1 + "/h" +

                    ((Truck_fee_150miles_Day1 !== 0) ? "+ $" + Truck_fee_150miles_Day1.toString() + " truck(s) fee" : "").toString()
                    :
                    "$" + Rate_card_150miles_Day1 + "/h " +
                    ((Truck_fee_150miles_Day1 !== 0) ? "+ $" + Truck_fee_150miles_Day1.toString() + " truck(s) fee" : "").toString()
                }
              </p>
            </li>
            : <> {(Truck_fee_150miles_Day1) ? <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Truck fee:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                  ${Truck_fee_150miles_Day1}
                </span>
              </p>
            </li>
              : ''}
            </>
          }

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Trucks:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Trucks_150miles_Day1}
              {twoDays150Miles.shuttleOnDay1 && ("+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${twoDays150Miles.quantityDay1})` : ''))}</p>
          </li>

          <ExtraOptionsResults
            department={department}
            selectedChargesNames={twoDays150Miles.selectedChargesNamesDay1}
            trucksCount={Trucks_150miles_Day1}
          />

          {isCuFtDepartment &&
            <AdditionalServicesResults
              selectedAdditionalServices={commonValues.selectedAdditionalServices}
              department={department}
              additionalServicesPlaceType={commonValues.additionalServicesPlaceType}
              Total_cubes={Total_cubes}
              trucksCount={Trucks_150miles_Day1}
            />
          }


          {(!isCuFtDepartment) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Double Drive:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {Double_drive_150miles_Day1}
              </p>
            </li>
            : ''}

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Time Range:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {getTotalTimeRange((department) ? department.minimumHours : 0, Lower_Number_of_Hours_150miles_Day1, Higher_Number_of_Hours_150miles_Day1)}</p>
          </li>

          {(commonValues.procedures.length > 0 && twoDays150Miles.proceduresOnDay1 && !twoDays150Miles.proceduresOnDay2 && commonValues.procedures.some(procedure => procedure !== 'None')) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Procedures:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>

              </p>
              <ul>
                {
                  commonValues.procedures.map((procedure) => {
                    if (procedure.procedureType !== '' && procedure.procedureType !== 'None') {
                      return <li>{procedure.procedureType + " x " + procedure.numberOfProcedures}:{
                        (Object.keys(department).length !== 0) && department.packing.cratings.map((crating, index) => {
                          if (procedure.procedureType === crating[`crateName${index}`]) {
                            return ' $' + (Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]));
                          }
                          else {
                            return ''
                          }
                        })
                      }</li>
                    }
                    else { return '' }
                  })
                }
              </ul>
            </li>
            :
            ''}

          {(commonValues.packing !== "No" && !isCuFtDepartment) ?
            (commonValues.packingKitOverride > 0) ?
              <li className={styles.paramsItem} style={{ color: "red" }}>
                <p className={styles.paramsName}>Packing Kit:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  {'$' + Packing_kit_Show} {commonValues.packing} [${Packing_kit_total_amount_with_taxes} with tax]
                </p>
              </li>
              : <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Packing Kit:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  {'$' + Packing_kit_Show} {(commonValues.packing === "Yes") ? "Full" : commonValues.packing} [${Packing_kit_total_amount_with_taxes} with tax]
                </p>
              </li>
            : ""}
          {(commonValues.unpacking === 'Same Day') &&
            <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '10px' }}>
              <span style={{ whiteSpace: 'nowrap' }}>UNPACKING {commonValues.partialUnPacking && "PARTIAL"}</span>
            </div>
          }

          {(isCuFtDepartment && Packing_Charge) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Packing Charge:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                ${Packing_Charge}
              </p>
            </li>
            : ''
          }
          <PackingSuppliesOnTheGo
            department={department}
            commonValues={commonValues}
          />

          {(isCuFtDepartment) ? <AdditionalServicesEstimateInfo commonValues={commonValues} department={department} totalCubes={Total_cubes} /> : ''}

          <h2 className={styles.titleCenter}>Day2 (Parking lot→Unloading)</h2>

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Movers:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Movers_150miles_Day2}
            </p>
          </li>

          {(!isCuFtDepartment) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Rate:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {(Total_Destinationfee_150miles > 0)
                  ?
                  (department.truckFee.tfdfCombined)
                    ? (department && (Object.keys(department).length !== 0) && department.rates && department.rates.calcMethod === 'deduct') ? "$" + Rate_cash_150miles_Day2 + "/h /"
                      + "$" + Rate_card_150miles_Day2 + "/h"
                      :
                      "$" + Rate_card_150miles_Day2 + "/h"
                    : (department.rates && department.rates.calcMethod === 'deduct') ? "$" + Rate_cash_150miles_Day2 + "/h /"
                      + "$" + Rate_card_150miles_Day2 + "/h" +
                      ((Truck_fee_150miles_Day2 !== 0) ? "+ $" + Truck_fee_150miles_Day2.toString() + " truck(s) fee" : "").toString()
                      :
                      "$" + Rate_card_150miles_Day2 + "/h" +
                      ((Truck_fee_150miles_Day2 !== 0) ? "+ $" + Truck_fee_150miles_Day2.toString() + " truck(s) fee" : "").toString()
                  : (department.rates && department.rates.calcMethod === 'deduct') ? "$" + Rate_cash_150miles_Day2 + "/h /"
                    + "$" + Rate_card_150miles_Day2 + "/h" +
                    ((Truck_fee_150miles_Day2 !== 0) ? "+ $" + Truck_fee_150miles_Day2.toString() + " truck(s) fee" : "").toString()
                    :
                    "$" + Rate_card_150miles_Day2 + "/h" +
                    ((Truck_fee_150miles_Day2 !== 0) ? "+ $" + Truck_fee_150miles_Day2.toString() + " truck(s) fee" : "").toString()

                }

              </p>
            </li>
            : <>{(Truck_fee_150miles_Day2) ? <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Truck fee:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                ${Truck_fee_150miles_Day2}
              </p>
            </li>
              : ''}</>
          }

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Trucks:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Trucks_150miles_Day2}
              {twoDays150Miles.shuttleOnDay2 && ("+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${twoDays150Miles.quantityDay2})` : ''))}</p>
          </li>
          {(BIGGEST_or_OK !== 'OK') ?
            <li className={styles.paramsItem}>
              {BIGGEST_or_OK}
            </li>
            : ""
          }
          {(TWO_Trips_Or_OK_Day2 !== 'OK') ?
            <li className={styles.paramsItem}>
              {TWO_Trips_Or_OK_Day2}
            </li>
            : ""
          }

          <ExtraOptionsResults
            department={department}
            selectedChargesNames={twoDays150Miles.selectedChargesNamesDay2}
            trucksCount={Trucks_150miles_Day2}
          />

          {(!isCuFtDepartment) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Double Drive:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {Double_drive_150miles_Day2}
              </p>
            </li>
            : ''}

          {(Destination_fee_150miles > 0) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Destination fee:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>$
                {(department.truckFee.tfdfCombined) ?
                  Total_Destinationfee_150miles + Truck_fee_150miles_Day1 + Truck_fee_150miles_Day1
                  : Total_Destinationfee_150miles
                }
              </p>
            </li>
            : ""}

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total time Range:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {getTotalTimeRange((department) ? department.minimumHours : 0, Lower_Number_of_Hours_150miles_Day2, Higher_Number_of_Hours_150miles_Day2)}</p>
          </li>

          {(commonValues.procedures.length > 0 && twoDays150Miles.proceduresOnDay2) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Procedures:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>

              </p>
              <ul>
                {
                  commonValues.procedures.map((procedure) => {
                    if (procedure.procedureType !== '' && procedure.procedureType !== 'None') {
                      return <li>{procedure.procedureType + " x " + procedure.numberOfProcedures}:{
                        (Object.keys(department).length !== 0) && department.packing.cratings.map((crating, index) => {
                          if (procedure.procedureType === crating[`crateName${index}`]) {
                            return ' $' + (Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]));
                          }
                          else {
                            return ''
                          }
                        })
                      }</li>
                    }
                    else { return '' }
                  })
                }
              </ul>
            </li>
            :
            ''}

          {(Heavy_fee > 0) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Extra Heavy Items Charge:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>{'$' + Heavy_fee}</p>
            </li>
            : ""}

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <span style={{ whiteSpace: 'nowrap' }}>{commonValues.liftGate && ' | LIFTGATE'}</span>
            <span style={{ whiteSpace: 'nowrap' }}>{commonValues.hardFloorCovers && " | HARDWOOD FLOOR COVERS"}</span>
            <span style={{ whiteSpace: 'nowrap' }}>{commonValues.filmFloorCovers && " | CARPET FLOOR COVERS "}</span>
            {selectedMiscCheckMarks.map(checkMark => <span style={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}>
              {' '} | {getCheckMarkLabelById(checkMark, department.miscCheckMarks.miscCheckMarks)}
            </span>)}
          </div>

          {(twoDays150Miles.additionalAmount) ? (
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Additional amount:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                ${twoDays150Miles.additionalAmount}
              </p>
            </li>) : ''
          }

          {(!isCuFtDepartment) ? <>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>NOT TO EXCEED PRICE:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>$
                {(department.rates && department.rates.calcMethod === 'deduct') ?
                  Total_Cash_150miles_2Days + ' cash / $' + Total_Card_150miles_2Days + " card"
                  :
                  <>
                    {Total_Card_150miles_2Days}
                  </>}
              </p>
            </li>
            {(department.rates && department.rates.calcMethod !== 'deduct') ?
              <div style={{ paddingLeft: '30px' }}>
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>NOT TO EXCEED PRICE with {getCurrentRatePercent(twoDays150Miles, department)}% cash discount:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>$
                    {Total_Cash_150miles_2Days}
                  </p>
                </li>
              </div>
              : ""}
          </> : ''}

          {isCuFtDepartment && <>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>FLAT RATE:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>$
                {(department.rates && department.rates.calcMethod === 'deduct') ?
                  Flat_rate_Cash_150_miles + ' cash / $' + Flat_rate_Card_150_miles + " card"
                  :
                  <>
                    {Flat_rate_Card_150_miles}
                  </>}
              </p>
            </li>
            {(department.rates && department.rates.calcMethod !== 'deduct') ?
              <div style={{ paddingLeft: '30px' }}>
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>FLAT RATE with {getCurrentRatePercent(twoDays150Miles, department)}% cash discount:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    $
                    {Flat_rate_Cash_150_miles}
                  </p>
                </li>
              </div>
              : ""}
          </>}

          <CalculationSeparateDayView commonValues={commonValues} anyCalculationType={twoDays150Miles} department={department}
            Trucks_Unpacking_Separate_Day={Trucks_Unpacking_Separate_Day}
            Movers_Unpacking_Separate_Day={Movers_Unpacking_Separate_Day}
            Unpacking_Separate_Day_Lower_hours={Unpacking_Separate_Day_Lower_hours}
            Unpacking_Separate_Day_Higher_hours={Unpacking_Separate_Day_Higher_hours}
            Total_Card_Unpacking_Separate_Day={Total_Card_Unpacking_Separate_Day}
            Total_Cash_Unpacking_Separate_Day={Total_Cash_Unpacking_Separate_Day}
            Rate_cash_Unpacking_Separate_Day={Rate_cash_Unpacking_Separate_Day}
            Rate_card_Unpacking_Separate_Day={Rate_card_Unpacking_Separate_Day}
            Truck_fee_Unpacking_Separate_Day={Truck_fee_Unpacking_Separate_Day}
            Flat_rate_Unpacking_Separate_Day={Flat_rate_Unpacking_Separate_Day}
            Flat_rate_Unpacking_Separate_Day_cash={Flat_rate_Unpacking_Separate_Day_cash}
            isCuFtDepartment={isCuFtDepartment}
            UnPacking_Charge={UnPacking_Charge}
            highLightItem={highLightItem}
            scrollRefs={scrollRefs}
          />
        </div>
        : ""}
    </ul>
  );
};
