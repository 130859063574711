import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { SocketContext } from '../../context/socket';
import useCollapse from 'react-collapsed'

import { getCheckMarkLabelById, getCurrentRatePercent, getHigherNumberOfHours, getLowerNumberOfHours, getTagsOptions, replaceSpaces } from '../../util/utils';

// Styles
import styles from "./TemplateOneDayParams.module.css";
//Components
import { TemplateSeparateDayView } from '../template-separate-day-view/Template-separate-day-view';
import { useDispatch, useSelector } from 'react-redux';
import { getUserName } from '../../clientStore/authSlice/auth-slice';
import { CalculationTabOneDayValues } from '../calculation-tab-one-day-values/Calculation-tab-one-day-values';
import { TemplatesExtraOptionsResults } from '../templates-extra-options-results/Templates-extra-options-results';
import { TemplatesAdditionalServicesResults } from '../templates-additional-services-results/Templates-additional-services-results';
import { setTotal_cubesG, setTotalFragileBoxes } from '../../clientStore/calculatedParamsSlice/calculated-params-slice';
import { CalculationOneDayParams } from '../calculation-one-day-params/Calculation-one-day-params';

export const TemplateOneDayParams = ({
  highLightItem,
  company,
  department,
  templateForm,
  rates,
  onCalcFormValueChange,
  resetLastTarget,
  setOptions,
  users,
  departments,
  lastTarget,
  setLastTarget,
  checkMarkCheckboxValueChange,
  additionalServicesCheckboxValueChange,
  setCalculationFormValue
}) => {

  const dispatch = useDispatch();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const userName = useSelector(getUserName);
  const scrollRefs = useRef([]);
  const calculationForm = templateForm.templateCalcObj;
  const [loading, setLoading] = useState(false);


  const scrollKeys = [
    'username', 'department', 'truckloads', 'additionalStopsCount', 'movers',
    'moversToLoad', 'moversToDrivesAndUnload',
    'rate', 'rateCash', 'rateCashLoad', 'rateCardLoad', 'truckfee',
    'rateCardUnload', 'rateCashUnload', 'trucks', 'shuttle', 'doubleDrive', 'twoTrips',
    'biggestOrOk', 'lowerNumberOfHours', 'higherNumberOfHours', 'packingKit', 'packingType',
    'packingKitWithTax', 'smallBox', 'mediumBox', 'largeBox', 'rollOfPaper', 'rollOfBubble',
    'liftGate', 'hardwoodFloorCovers', 'carpetFloorCovers', 'notToExceedPrice',
    'notToExceedPricePercent', 'cashDiscount', 'sameDayUnpack', 'separateDayUnpack',
    'trucksUnpackingSeparateDay', 'moversUnpackingSeparateDay', 'unpackingSeparateDayLowerhours',
    'unpackingSeparateDayHigherhours', 'totalCardUnpackingSeparateDay', 'totalCashUnpackingSeparateDay',
    'rateCashUnpackingSeparateDay', 'rateCardUnpackingSeparateDay', 'truckFeeUnpackingSeparateDay',
    'truckFeeUnpackingSeparateDay', 'combinedMileage', 'calculationNumber', 'trucksPercent', 'truckloadsRoundDown'];
  if (company) {
    if (company.extraOptCharges) {
      company.extraOptCharges.forEach((charge, index) => {
        scrollKeys.push(`chIdName_${index}`)
        scrollKeys.push(`chIdAmount_${index}`)
      })
    }
    if (company.additProcedures) {
      company.additProcedures.forEach((procedure, index) => {
        scrollKeys.push(`pIdName_${index}`)
        scrollKeys.push(`pIdCost_${index}`)
        scrollKeys.push(`pIdQty_${index}`)
      })
    }
    if (company.miscCheckMarks) {
      company.miscCheckMarks.forEach((checkMark, index) => {
        scrollKeys.push(`mChId_${index}`)
      })
    }
  }
  scrollKeys.forEach(element => {
    scrollRefs.current[element] = React.createRef()
  });

  const commonValues = (calculationForm) ? calculationForm.commonValues : {};
  const oneDayPacking = (calculationForm) ? calculationForm.oneDayPacking : {};
  const [Total_cubes, setTotal_cubes] = useState(0);
  const [Total_small_boxes, setTotal_small_boxes] = useState(0);
  const [Total_medium_boxes, setTotal_medium_boxes] = useState(0)
  const [Total_fragile_boxes, setTotal_fragile_boxes] = useState(0)
  const [Total_box_cubes, setTotal_box_cubes] = useState(0)
  const [Stop_1_Truckload, setStop_1_Truckload] = useState(0)
  const [Stop_CN_Truckload, setStop_CN_Truckload] = useState(0)
  const [Charge_per_mile_amount, setCharge_per_mile_amount] = useState(0)
  const [Movers_to_load_One_Day_hours, setMovers_to_load_One_Day_hours] = useState(0)
  const [Loading_hours_One_Day, setLoading_hours_One_Day] = useState(0)
  const [Adjusted_loading_time_One_Day, setAdjusted_loading_time_One_Day] = useState(0)
  const [Drivetime_One_Day, setDrivetime_One_Day] = useState(0)
  const [Movers_to_unload_One_Day_hours, setMovers_to_unload_One_Day_hours] = useState(0)
  const [Unloading_hours_One_Day, setUnloading_hours_One_Day] = useState(0)
  const [Total_Hours_One_Day, setTotal_Hours_One_Day] = useState(0)
  const [Truckloads, setTruckloads] = useState(0)
  const [Total_Destinationfee_One_Day, setTotal_Destinationfee_One_Day] = useState(0)
  const [Rate_cash_Movers_To_Load_One_Day, setRate_cash_Movers_To_Load_One_Day] = useState(0)
  const [Rate_card_Movers_To_Load_One_Day, setRate_card_Movers_To_Load_One_Day] = useState(0)
  const [Truck_fee_One_Day, setTruck_fee_One_Day] = useState(0)
  const [Movers_One_Day, setMovers_One_Day] = useState(0)
  const [Rate_cash_One_Day, setRate_cash_One_Day] = useState(0)
  const [Rate_card_One_Day, setRate_card_One_Day] = useState(0)
  const [Rate_cash_Movers_To_Unload_One_Day, setRate_cash_Movers_To_Unload_One_Day] = useState(0)
  const [Rate_card_Movers_To_Unload_One_Day, setRate_card_Movers_To_Unload_One_Day] = useState(0)
  const [Trucks_One_Day, setTrucks_One_Day] = useState(0)
  const [TWO_Trips_Or_OK, setTWO_Trips_Or_OK] = useState('')
  const [BIGGEST_or_OK, setBIGGEST_or_OK] = useState('')
  const [Double_drive, setDouble_drive] = useState('')
  const [Lower_Number_of_Hours_One_Day, setLower_Number_of_Hours_One_Day] = useState(0)
  const [Higher_Number_of_Hours_One_Day, setHigher_Number_of_Hours_One_Day] = useState(0)
  const [Heavy_fee, setHeavy_fee] = useState(0)
  const [Packing_kit_Show, setPacking_kit_Show] = useState(0)
  const [Packing_kit_total_amount_with_taxes, setPacking_kit_total_amount_with_taxes] = useState(0)
  const [Total_Card_One_day, setTotal_Card_One_day] = useState(0)
  const [Total_Cash_One_day, setTotal_Cash_One_day] = useState(0)
  const [Combined_mileage, setCombined_mileage] = useState(0)

  const [Trucks_Unpacking_Separate_Day, setTrucks_Unpacking_Separate_Day] = useState(0)
  const [Movers_Unpacking_Separate_Day, setMovers_Unpacking_Separate_Day] = useState(0)
  const [Unpacking_Separate_Day_Lower_hours, setUnpacking_Separate_Day_Lower_hours] = useState(0)
  const [Unpacking_Separate_Day_Higher_hours, setUnpacking_Separate_Day_Higher_hours] = useState(0)
  const [Total_Card_Unpacking_Separate_Day, setTotal_Card_Unpacking_Separate_Day] = useState(0)
  const [Total_Cash_Unpacking_Separate_Day, setTotal_Cash_Unpacking_Separate_Day] = useState(0)
  const [Rate_cash_Unpacking_Separate_Day, setRate_cash_Unpacking_Separate_Day] = useState(0)
  const [Rate_card_Unpacking_Separate_Day, setRate_card_Unpacking_Separate_Day] = useState(0)
  const [Truck_fee_Unpacking_Separate_Day, setTruck_fee_Unpacking_Separate_Day] = useState(0)
  const [Flat_rate_One_Day, setFlat_rate_One_Day] = useState(0)
  const [Flat_rate_One_Day_cash, setFlat_rate_One_Day_cash] = useState(0)
  const [Flat_rate_Unpacking_Separate_Day, setFlat_rate_Unpacking_Separate_Day] = useState(0)
  const [Flat_rate_Unpacking_Separate_Day_cash, setFlat_rate_Unpacking_Separate_Day_cash] = useState(0)
  const [Packing_Charge, setPacking_Charge] = useState(0)
  const [UnPacking_Charge, setUnPacking_Charge] = useState(0)
  const isCuFtDepartment = department?.type === 'cuft';
  const socket = useContext(SocketContext);

  useEffect(() => {

    socket.on('Total_cubes', (data) => { setTotal_cubes(data); dispatch(setTotal_cubesG(data)); });
    socket.on('Total_small_boxes', (data) => {
      setTotal_small_boxes(data)
      dispatch(setTotalFragileBoxes(data));
    });
    socket.on('Total_medium_boxes', (data) => {
      setTotal_medium_boxes(data)
      dispatch(setTotalFragileBoxes(data));
    });
    socket.on('Total_fragile_boxes', (data) => {
      setTotal_fragile_boxes(data);
      dispatch(setTotalFragileBoxes(data));
    });
    socket.on('Total_box_cubes', (data) => {
      setTotal_box_cubes(data)
    });
    socket.on('Stop_1_Truckload', (data) => {
      setStop_1_Truckload(data)
    });
    socket.on('Stop_CN_Truckload', (data) => {
      setStop_CN_Truckload(data)
    });
    socket.on('Charge_per_mile_amount', (data) => {
      setCharge_per_mile_amount(data)
    });
    socket.on('Movers_to_load_One_Day_hours', (data) => {
      setMovers_to_load_One_Day_hours(data)
    });
    socket.on('Loading_hours_One_Day', (data) => {
      setLoading_hours_One_Day(data)
    });
    socket.on('Adjusted_loading_time_One_Day', (data) => {
      setAdjusted_loading_time_One_Day(data)
    });
    socket.on('Drivetime_One_Day', (data) => {
      setDrivetime_One_Day(data)
    });
    socket.on('Movers_to_unload_One_Day_hours', (data) => {
      setMovers_to_unload_One_Day_hours(data)
    });
    socket.on('Unloading_hours_One_Day', (data) => {
      setUnloading_hours_One_Day(data)
    });
    socket.on('Total_Hours_One_Day', (data) => {
      setTotal_Hours_One_Day(data)
    });
    socket.on('Truckloads', (data) => {
      setTruckloads(data)
    });
    socket.on('Total_Destinationfee_One_Day', (data) => {
      setTotal_Destinationfee_One_Day(data)
    });
    socket.on('Rate_cash_Movers_To_Load_One_Day', (data) => {
      setRate_cash_Movers_To_Load_One_Day(data)
    });
    socket.on('Rate_card_Movers_To_Load_One_Day', (data) => {
      setRate_card_Movers_To_Load_One_Day(data)
    });
    socket.on('Truck_fee_One_Day', (data) => {
      setTruck_fee_One_Day(data)
    });
    socket.on('Movers_One_Day', (data) => {
      setMovers_One_Day(data)
    });
    socket.on('Rate_cash_One_Day', (data) => {
      setRate_cash_One_Day(data)
    });
    socket.on('Rate_card_One_Day', (data) => {
      setRate_card_One_Day(data)
    });
    socket.on('Rate_cash_Movers_To_Unload_One_Day', (data) => {
      setRate_cash_Movers_To_Unload_One_Day(data)
    });
    socket.on('Rate_card_Movers_To_Unload_One_Day', (data) => {
      setRate_card_Movers_To_Unload_One_Day(data)
    });
    socket.on('Trucks_One_Day', (data) => {
      setTrucks_One_Day(data)
    });
    socket.on('TWO_Trips_Or_OK', (data) => {
      setTWO_Trips_Or_OK(data)
    });
    socket.on('BIGGEST_or_OK', (data) => {
      setBIGGEST_or_OK(data)
    });
    socket.on('Double_drive', (data) => {
      setDouble_drive(data)
    });
    socket.on('Lower_Number_of_Hours_One_Day', (data) => {
      setLower_Number_of_Hours_One_Day(data)
    });
    socket.on('Higher_Number_of_Hours_One_Day', (data) => {
      setHigher_Number_of_Hours_One_Day(data)
    });
    socket.on('Heavy_fee', (data) => {
      setHeavy_fee(data)
    });
    socket.on('Packing_kit_Show', (data) => {
      setPacking_kit_Show(data)
    });
    socket.on('Packing_kit_total_amount_with_taxes', (data) => {
      setPacking_kit_total_amount_with_taxes(data)
    });
    socket.on('Total_Card_One_day', (data) => {
      setTotal_Card_One_day(data)
    });
    socket.on('Total_Cash_One_day', (data) => {
      setTotal_Cash_One_day(data)
    });
    socket.on('Flat_rate_One_Day', (data) => setFlat_rate_One_Day(data));
    socket.on('Flat_rate_One_Day_cash', (data) => setFlat_rate_One_Day_cash(data));
    socket.on('Packing_Charge', (data) => setPacking_Charge(data));
    socket.on('UnPacking_Charge', (data) => setUnPacking_Charge(data));

    socket.on('Combined_mileage', (data) => { setCombined_mileage(data) });
    socket.on('Trucks_Unpacking_Separate_Day', (data) => setTrucks_Unpacking_Separate_Day(data));
    socket.on('Movers_Unpacking_Separate_Day', (data) => setMovers_Unpacking_Separate_Day(data));
    socket.on('Unpacking_Separate_Day_Lower_hours', (data) => setUnpacking_Separate_Day_Lower_hours(data));
    socket.on('Unpacking_Separate_Day_Higher_hours', (data) => setUnpacking_Separate_Day_Higher_hours(data));
    socket.on('Total_Card_Unpacking_Separate_Day', (data) => setTotal_Card_Unpacking_Separate_Day(data));
    socket.on('Total_Cash_Unpacking_Separate_Day', (data) => setTotal_Cash_Unpacking_Separate_Day(data));
    socket.on('Rate_cash_Unpacking_Separate_Day', (data) => setRate_cash_Unpacking_Separate_Day(data));
    socket.on('Rate_card_Unpacking_Separate_Day', (data) => setRate_card_Unpacking_Separate_Day(data));
    socket.on('Truck_fee_Unpacking_Separate_Day', (data) => setTruck_fee_Unpacking_Separate_Day(data));
    socket.on('Flat_rate_Unpacking_Separate_Day', (data) => setFlat_rate_Unpacking_Separate_Day(data));
    socket.on('Flat_rate_Unpacking_Separate_Day_cash', (data) => setFlat_rate_Unpacking_Separate_Day_cash(data));
  }, [calculationForm])


  const sepCheckboxValueChange = (evt) => {
    resetLastTarget();

    switch (evt.target.value) {
      case 'true':
        return onCalcFormValueChange(`commonValues.${evt.target.name}`, 'set', false);
      case 'false':
        return onCalcFormValueChange(`commonValues.${evt.target.name}`, 'set', true);
      default:
        break;
    }
  };


  useEffect(() => {

    if (highLightItem) {
      try {
        scrollRefs.current[highLightItem].current.scrollIntoView({ block: 'nearest' });
      }
      catch {

      }
    }
  }, [highLightItem]);

  useEffect(() => {
    let currentOptions = getTagsOptions({
      company: company, templateCalcType: templateForm.templateCalcType, users: users, departments: departments, calculation: templateForm.templateCalcObj,
      calculatedParams: {
        Total_cubes,
        Total_small_boxes,
        Total_medium_boxes,
        Total_fragile_boxes,
        Total_box_cubes,
        Stop_1_Truckload,
        Stop_CN_Truckload,
        Charge_per_mile_amount,
        Movers_to_load_One_Day_hours,
        Loading_hours_One_Day,
        Adjusted_loading_time_One_Day,
        Drivetime_One_Day,
        Movers_to_unload_One_Day_hours,
        Unloading_hours_One_Day,
        Total_Hours_One_Day,
        Truckloads,
        Total_Destinationfee_One_Day,
        Rate_cash_Movers_To_Load_One_Day,
        Rate_card_Movers_To_Load_One_Day,
        Truck_fee_One_Day,
        Movers_One_Day,
        Rate_cash_One_Day,
        Rate_card_One_Day,
        Rate_cash_Movers_To_Unload_One_Day,
        Rate_card_Movers_To_Unload_One_Day,
        Trucks_One_Day,
        TWO_Trips_Or_OK,
        BIGGEST_or_OK,
        Double_drive,
        Lower_Number_of_Hours_One_Day,
        Higher_Number_of_Hours_One_Day,
        Heavy_fee,
        Packing_kit_Show,
        Packing_kit_total_amount_with_taxes,
        Total_Card_One_day,
        Total_Cash_One_day,
        Combined_mileage,
        Flat_rate_One_Day,
        Flat_rate_One_Day_cash,
        Packing_Charge,
        UnPacking_Charge,
        Flat_rate_Unpacking_Separate_Day,
        Flat_rate_Unpacking_Separate_Day_cash,
      }
    });

    setOptions(currentOptions);
  }, [templateForm.tags, templateForm.templateCalcObj]);


  return (
    <div className={styles.parentCont}>
      {(calculationForm && Object.keys(calculationForm).length) ?
        <CalculationTabOneDayValues
          rates={(rates.length > 0) ? rates : (calculationForm.department && calculationForm.department.rates) ? calculationForm.department.rates.ratesList : []}
          formData={calculationForm}
          formChange={onCalcFormValueChange}
          lastTarget={lastTarget}
          setLastTarget={setLastTarget}
          resetLastTarget={resetLastTarget}
          sepCheckboxValueChange={sepCheckboxValueChange}
          checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
          additionalServicesCheckboxValueChange={additionalServicesCheckboxValueChange}
          setCalculationFormValue={setCalculationFormValue}
        />
        : ""}

      <div>
        <h3 className={`${styles.title} ${(highLightItem === 'username') ? styles.highLighted : ""}`} ref={scrollRefs?.current['username']}>{userName}</h3>
        <h3 className={`${styles.title}`}><span className={`${(highLightItem === 'calculationNumber') ? styles.highLighted : ""}`} ref={scrollRefs?.current['calculationNumber']}>{(calculationForm) ? calculationForm.calculationNumber : ""} </span><span className={`${(highLightItem === 'department') ? styles.highLighted : ""}`} ref={scrollRefs?.current['department']}>{calculationForm && calculationForm.department && calculationForm.department.departmentName}</span>{/*  <span className={`${(highLightItem === 'rate') ? styles.highLighted : ""}`}>XP</span> */}</h3>
        <div className={`${styles.title}`}>
          <p className={styles.calculationDate}>

            {(calculationForm && Object.keys(calculationForm).length && calculationForm.oneDayPacking.firstDayDate) ? <>
              <span className={`${(highLightItem === 'day1weekday') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1weekday']}>{new Date(calculationForm.oneDayPacking.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' })}, </span>
              <span className={`${(highLightItem === 'day1month') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1month']}>{new Date(calculationForm.oneDayPacking.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day1date') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1date']}>{new Date(calculationForm.oneDayPacking.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day1year') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1year']}>{new Date(calculationForm.oneDayPacking.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })}</span>
            </> : ""}
          </p>
        </div>
        <CalculationOneDayParams
          commonValues={commonValues}
          oneDayPacking={oneDayPacking}
          department={department}
          selectedMiscCheckMarks={calculationForm.selectedMiscCheckMarks}
          calculationData={calculationForm}
          popUpShown={false}
          scrollRefs={scrollRefs}
          highLightItem={highLightItem}
          company={company}
        />

      </div>
    </div >
  );
};

