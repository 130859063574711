export const furnitureDataModel = {
  authorId: '',
  companyId: '',
  items: [{
    itemName0: 'Boxes',
    rangeMin0: 0,
    rangeMax0: 0,
    mostCommonCuFt0: 0,
  }],
  includeCuFtPerItem: false,
  useSymbolBeforeItems: false,
  furnitureItemsSymbol: '',
  bold: false,
  italic: false,
  underline: false,
  fontFamily: 'Verdana',
  fontSize: 12,
  inventoryListOptions: {
    default: {
      cuftText: '',
      includeBoxesOnInv: false,
      boxesText: '',
      convertToMediumBoxes: false,
      convertToMediumBoxesWB: false,
      adjustTotalCuFt: false,
      adjustTotalCuFtWB: false,
      includeWBBoxesOnInv: false,
      combinePBOPBMBoxes: false,
      WBBoxesText: '',
    }
  }
};  
