// QuantityInput.jsx
import React, { useEffect } from 'react';
import styles from './QuantityInput.module.css';

const QuantityInput = ({ value, name, onFormValueChange, itemIndex, furnitureType, selectedRoomIndex, furnitureItem, furnitureItemIndex, hasRole }) => {

  const handleChange = (e) => {
    if (e.target.value === '' || e.target.value >= 0) {
      onFormValueChange(`inventory.rooms.${selectedRoomIndex}.${furnitureType}.${itemIndex}.${name}`, 'set', e.target.value)
    }
  }

  const handleMinus = () => {
    if (value > 1) {
      onFormValueChange(`inventory.rooms.${selectedRoomIndex}.${furnitureType}.${itemIndex}.${name}`, 'set', value - 1)
    }
  }

  const handlePlus = () => {
    if (value >= 0) {
      onFormValueChange(`inventory.rooms.${selectedRoomIndex}.${furnitureType}.${itemIndex}.${name}`, 'set', value + 1)
    }
  }

  const getInputColor = () => {
    if (furnitureItemIndex > -1) {
      if (value < furnitureItem[`rangeMin${furnitureItemIndex}`]) {
        return 'green'; // Value is less than rangeMin
      } else if (value > furnitureItem[`rangeMax${furnitureItemIndex}`]) {
        return 'red'; // Value is greater than rangeMax
      }
      return 'white'; // Default color
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowUp') {
      handlePlus();
    } else if (event.key === 'ArrowDown') {
      handleMinus();
    }
  };

  return (
    <div className={styles.buttonContainer}>
      <div className={`${styles.button} ${!hasRole ? styles.buttonDisabled : ''}`} onClick={hasRole ? handleMinus : null}>
        <svg className={styles.svgIcon} viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg" >
          <rect x="4" y="11" width="16" height="2" />
        </svg>
      </div>
      <input
        type="number"
        className={styles.inputBox}
        value={value}
        onChange={handleChange}
        style={{ backgroundColor: getInputColor() }}
        /* onKeyDown={handleKeyDown} */
        disabled={!hasRole}
      />
      <div className={`${styles.button} ${!hasRole ? styles.buttonDisabled : ''}`} onClick={hasRole ? handlePlus : null}>
        <svg className={styles.svgIcon} viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
          <rect x="11" y="4" width="2" height="16" />
          <rect x="4" y="11" width="16" height="2" />
        </svg>
      </div>
    </div>
  );
};

export default QuantityInput;